@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');
@font-face {
  font-family: "BelledeMai";
  src: local("BelledeMai"),
  url("./assets/fonts/BelledeMai5.0-Heavy.otf") format("opentype");
}


body {
  font-family: 'Inter', sans-serif;

}

img {
  max-width: 100%;
  height: auto;
}

.page_container_box {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-size: cover;
}

.grain_canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}


